@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-area-with-sidemenu {
  width: calc(100vw - theme('width.48'));
}

.content-area-with-sidedrawer {
  width: calc(100% - theme('width.96'));
  min-width: 500px;
}

.content-area-with-sidedrawer--slim {
  width: calc(100% - theme('width.72'));
  min-width: 650px;
}
@media screen and (max-width: 650px) {
  .content-area-with-sidedrawer {
    width: calc(100% - theme('width.96'));
    min-width: 200px;
  }
}

.side-drawer__wrapper {
  top: 7.75rem;
  height: calc(100vh - theme('height.24'));
  @apply fixed right-0 z-10 p-5 overflow-y-scroll border-l border-gray-200 shadow-sm max-w-1/2-screen bg-gray-50
}

.side-drawer__wrapper--sheet-editor {
  height: calc(100vh - 104px);
}

.side-drawer__wrapper--work-complete-editor {
  height: calc(100vh - 95px);
}

.bottom-drawer__wrapper--work-complete-editor {
  height: 260px;
  max-width: 270px;
  width: 270px;
}

.sidedrawer-body-height {
  height: calc(100vh - theme('height.24') - theme('height.32'));
}

#datepicker_block .react-datepicker-wrapper {
  @apply block
}
